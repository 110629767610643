.feedbacks {
  h1 {
    margin-bottom: 0;
  }
  .anticon-check-circle {
    display: none;
  }
  .time-ago {
    font-size: 12px;
  }
}
@primary-color: #f0941e;@primary-color-hover: #ff6d00;@btn-font-weight: 500;@text-color: #212529;@link-color: #1172c4;@btn-default-border: #f0941e;@btn-default-color: #f0941e;@success-color: #007600;