@import '../variables.less';

.dashboard-item {
  padding: 64px @sm-horizontal-padding;

  @media screen and (max-width: 576px) {
    padding: 44px @sm-horizontal-padding;
  }
}

.dashboard-grey-item {
  background-color: rgba(211, 206, 230, .13);
}

.astro-g-working {
  hr {
    margin-top: 70%;
    width: 100px;
    border: 0 none;
    border-top: 2px dashed #f0941e;
    background: none;
    height: 0;
  }

  .work-diagram {
    width: 100px;

    img {
      height: 100px;
      width: 100px;
    }

    .step-text {
      width: 100px;
      margin-top: 16px;
      text-align: center;
      display: flex;
      justify-content: center;
    }
  }

  @media screen and (max-width: 576px) {
    .work-diagram {
      margin-top: 20px;
      width: 50px;
    }

    .work-diagram img {
      height: 50px;
      width: 50px;
    }

    hr {
      //margin-top: 44px;
      width: 24px;
    }

    .step-text {
      margin-top: 8px;
      width: 50px;
      font-family: Metropolis-Light, sans-serif;
      font-size: 9px;
    }
  }

}

.featured-astrologers {
  .slick-next {
    top: 40%;
    right: -8px;
  }

  .slick-prev {
    left: -8px;
  }

  .slick-next, .slick-prev {
    top: 40%;
    width: 44px;
    height: 44px;
    z-index: 99;

    button {
      background: transparent;
      border: transparent;
    }
  }

  .slick-next:disabled, .slick-prev:disabled {
    opacity: 0.4;
  }
}
@primary-color: #f0941e;@primary-color-hover: #ff6d00;@btn-font-weight: 500;@text-color: #212529;@link-color: #1172c4;@btn-default-border: #f0941e;@btn-default-color: #f0941e;@success-color: #007600;