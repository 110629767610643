.consult-detail {
  .note {
    background: #F1F3F475;
    display: flex;
    flex-direction: column;
    margin-top: 12px;
    padding: 8px 12px;
  }

  .action-btn {
    width: 232px;
    @media screen and (max-width: 576px) {
      width: 100%
    }
  }
}
@primary-color: #f0941e;@primary-color-hover: #ff6d00;@btn-font-weight: 500;@text-color: #212529;@link-color: #1172c4;@btn-default-border: #f0941e;@btn-default-color: #f0941e;@success-color: #007600;