@import '../variables.less';
@import '~antd/es/style/themes/default.less';

#con {
  min-height: 100%;
  position: relative;
}

#head {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  @media (max-width: 576px) {
    padding: 0;
  }
}

#content {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: white;
}

#footer {
  width: 100%;
  height: @footer-height;
}

.app-logo {
  width: @app-logo-width;
  cursor: pointer;
}

.navbar {
  background: transparent;
  z-index: 99;
}

.navbar.border {
  box-shadow: 0 2px 2px -2px rgb(0 0 0 / 15%);
}
@primary-color: #f0941e;@primary-color-hover: #ff6d00;@btn-font-weight: 500;@text-color: #212529;@link-color: #1172c4;@btn-default-border: #f0941e;@btn-default-color: #f0941e;@success-color: #007600;