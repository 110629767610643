* {
    font-family: Metropolis, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@font-face {
    font-family: 'Metropolis';
    src: local('Metropolis-Regular'), url(./Metropolis-Regular.otf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis';
    src: local('Metropolis-Italic'), url(./Metropolis-RegularItalic.otf) format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Metropolis';
    src: local('Metropolis-Medium'), url(./Metropolis-Medium.otf) format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis';
    src: local('Metropolis-MediumItalic'), url(./Metropolis-MediumItalic.otf) format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Metropolis';
    src: local('Metropolis-Bold'), url(./Metropolis-Bold.otf) format(' truetype ');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis';
    src: local('Metropolis-BoldItalic'), url(./Metropolis-BoldItalic.otf) format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Metropolis';
    src: local('Metropolis-Light'), url(./Metropolis-Light.otf) format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis';
    src: local('Metropolis-LightItalic'), url(./Metropolis-LightItalic.otf) format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Metropolis';
    src: local('Metropolis-ExtraLight'), url(./Metropolis-ExtraLight.otf) format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis';
    src: local('Metropolis-ExtraLightItalic'), url(./Metropolis-ExtraLightItalic.otf) format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Metropolis';
    src: local('Metropolis-Thin'), url(./Metropolis-Thin.otf) format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis';
    src: local('Metropolis-Thin'), url(./Metropolis-ThinItalic.otf) format('truetype');
    font-weight: 100;
    font-style: italic;
}
@primary-color: #f0941e;@primary-color-hover: #ff6d00;@btn-font-weight: 500;@text-color: #212529;@link-color: #1172c4;@btn-default-border: #f0941e;@btn-default-color: #f0941e;@success-color: #007600;