@import '~antd/es/style/themes/default.less';
@import '../variables.less';

.astro-intro {
  position: relative;
  width: 100%;

  .bg-image {
    width: 100%;
    height: 100%;
  }

  .container {
    padding-top: calc(@layout-header-height + @layout-header-height);
    padding-bottom: @layout-header-height;

    @media (max-width: 576px) {
      padding: calc(@layout-header-height + 44px) @sm-horizontal-padding calc(@layout-header-height - 12px);
    }

    .img-container {
      padding: 0 84px;
    }

    .ant-select-selector {
      height: 44px;
      background: transparent;
      border: 1px solid @primary-color;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;

      .ant-select-selection-search {
        input {
          height: 44px;
        }
      }

      .ant-select-selection-placeholder {
        color: rgb(128, 128, 128);;
      }
    }
  }
}
@primary-color: #f0941e;@primary-color-hover: #ff6d00;@btn-font-weight: 500;@text-color: #212529;@link-color: #1172c4;@btn-default-border: #f0941e;@btn-default-color: #f0941e;@success-color: #007600;