@import '~antd/es/style/themes/default.less';

.slot {
  padding: 6px 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;

  .time {
    font-weight: 500;
    font-size: 12px;
  }

  .rate {
    font-weight: 700;
  }

  &.booked {
    background: @primary-4;
  }

  &.locked {
    background: @primary-4;
  }

  &.available {
    border: 1px solid @success-color;
    cursor: pointer;
    color: #00000070;
  }

  &.available:hover {
    border: 1px solid @green-4;
    background: @green-4;
    color: white;
  }

  &.expired {
    background: #EEEEEE80;
    color: #00000020;
  }
}



@primary-color: #f0941e;@primary-color-hover: #ff6d00;@btn-font-weight: 500;@text-color: #212529;@link-color: #1172c4;@btn-default-border: #f0941e;@btn-default-color: #f0941e;@success-color: #007600;