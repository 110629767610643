@import '~antd/es/style/themes/default.less';

.mob-calendar {
  .cal-header {
    button {
      background: transparent;
      border: transparent;
      cursor: pointer;
      color: #212121;
    }

    button:disabled {
      cursor: default;
      color: #c8c7c7;
    }
  }

  .days {
    display: flex;
    gap: 10px;

    .day {
      flex-grow: 1;
      flex-basis: 0;
      display: flex;
      flex-direction: column;

      .weekday {
        text-align: right;
        padding: 4px
      }

      .day-number {
        height: 64px;
        border-top: 2px solid #f0f0f0;
        padding: 4px;
        text-align: right;

        &.selected {
          border-top: 2px solid @primary-color;
          background: @primary-1;
        }

      }
    }
  }
}


.dp-container {
  display: flex;
  width: 100%;
  background: inherit;


  .buttonWrapper {
    display: flex;
    align-items: flex-end;
    z-index: 2;
    background-color: transparent;
  }

  .button {
    border: none;
    text-decoration: none;
    cursor: pointer;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    color: white;
    font-size: 20px;
    font-weight: bold;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin-bottom: 13px;
  }

  .dateListScrollable {
    display: flex;
    overflow-x: scroll;
    scrollbar-width: none;
    margin: 2px 0 2px -40px;
    -webkit-overflow-scrolling: touch;
  }

  .dateListScrollable::-webkit-scrollbar {
    -webkit-appearance: none;
    display: none;
  }

  .monthContainer {
    display: flex;
    flex-direction: column;
  }

  .monthYearLabel {
    align-self: flex-start;
    z-index: 3;
    font-size: 15px;
    font-weight: bold;
    position: sticky;
    top: 0;
    left: 0;
    width: max-content;
    margin: 0 14px 10px 0;
  }

  .dateDayItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 0 0 0 5px;
    width: 100px;
    height: 54px;
    flex-shrink: 0;
  }

  .daysContainer {
    display: flex;
    z-index: 1;
    border-bottom: solid 1px rgba(0, 0, 0, 0.09);
  }

  .dayLabel {
    font-size: 12px;
    margin: 4px 0 0 0;
  }

  .dateLabel {
    font-size: 18px;
  }

}
@primary-color: #f0941e;@primary-color-hover: #ff6d00;@btn-font-weight: 500;@text-color: #212529;@link-color: #1172c4;@btn-default-border: #f0941e;@btn-default-color: #f0941e;@success-color: #007600;