.settings {
  padding-left: 64px;
  padding-right: 64px;

  .ant-input-affix-wrapper, .ant-form-item-control-input-content > .ant-input, .ant-picker {
    border: 1px solid #f1f1f1;
  }

  ul {
    height: 100%;
  }

  @media screen and (max-width: 576px) {
    padding-left: 0;
    padding-right: 0;
  }

  .content {
    padding-top: 20px;
    padding-left: 64px;
    padding-right: 184px;

    @media screen and (max-width: 576px) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

}
@primary-color: #f0941e;@primary-color-hover: #ff6d00;@btn-font-weight: 500;@text-color: #212529;@link-color: #1172c4;@btn-default-border: #f0941e;@btn-default-color: #f0941e;@success-color: #007600;