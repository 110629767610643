.web-calendar {
  .cal-header {
    padding-top: 8px;
    padding-bottom: 16px;

    button {
      background: transparent;
      border: transparent;
      cursor: pointer;
      color: #212121;
    }
    button:disabled {
      cursor: default;
      color: #c8c7c7;
    }
  }

  cursor: pointer;
  color: #212121;

}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
  height: 49px;
}
@primary-color: #f0941e;@primary-color-hover: #ff6d00;@btn-font-weight: 500;@text-color: #212529;@link-color: #1172c4;@btn-default-border: #f0941e;@btn-default-color: #f0941e;@success-color: #007600;