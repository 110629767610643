@import '~antd/es/style/themes/default.less';

.write-review {
  padding-left: 128px;
  padding-right: 128px;

  @media screen and (max-width: 576px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  .separator {
    background: @divider-color;
    width: 1px;
    height: 480px;
  }

  .col-pt {
    margin-top: 32px
  }

  .rate {
    font-size: 24px;
  }

}
@primary-color: #f0941e;@primary-color-hover: #ff6d00;@btn-font-weight: 500;@text-color: #212529;@link-color: #1172c4;@btn-default-border: #f0941e;@btn-default-color: #f0941e;@success-color: #007600;