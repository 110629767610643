.ag-footer {
  .details {
    width: 100%;
    background: #f7f7f7;
    padding: 44px 0;

    .about-us {
      a {
        color: black;
      }

      a:hover {
        color: #00000080
      }
    }

    .sm-item {
      margin-top: -12px;
      cursor: pointer;
      font-size: 10px;
      font-weight: 700;

      .facebook {
        color: #4267b2;
      }

      .instagram {
        color: #ed0156;
      }

      .youtube {
        color: #ff0000;
      }

      .contact {
        float: right;
        margin-right: 100px;
      }

      span {
        display: block;
        margin-top: -20px;
      }
    }

  }

  .copyright {
    background-color: #0f0a23;
    padding: 16px 0;
  }

}


@primary-color: #f0941e;@primary-color-hover: #ff6d00;@btn-font-weight: 500;@text-color: #212529;@link-color: #1172c4;@btn-default-border: #f0941e;@btn-default-color: #f0941e;@success-color: #007600;