.meet-info {
  border: thin solid #dadce0;
  border-radius: .5rem;
  width: 100%;

  .meet-item {
    padding: 16px 8px;
  }

  .icon {
    padding: 0 10px;
    font-size: 17px;
  }

  .divider {
    background: #dadce0;
    width: 100%;
    height: 1px;
  }

  .title {
    font-size: 17px;
    font-weight: 500;
  }

  .desc {
    font-size: 13px;
    font-weight: 500;
  }

}
@primary-color: #f0941e;@primary-color-hover: #ff6d00;@btn-font-weight: 500;@text-color: #212529;@link-color: #1172c4;@btn-default-border: #f0941e;@btn-default-color: #f0941e;@success-color: #007600;