@import '~antd/es/style/themes/default.less';
@import '~antd/dist/antd.less';
@import 'assets/fonts/index.css';

body {
  margin: 0;
  color: #212529;
  background: @text-color;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
  height: 100%
}

.white {
  color: white !important;
}

.primary-color {
  color: @primary-color !important;
}

.primary-color-disabled {
  color: @primary-color + 50;
}

.w100 {
  width: 100% !important;
}

.h100 {
  height: 100% !important;
}

.text-center {
  display: block !important;
  text-align: center !important;
}

.mt-1 {
  margin-top: 8px;
}

.mt-2 {
  margin-top: 12px;
}

.mt-3 {
  margin-top: 16px;
}

.mt-4 {
  margin-top: 20px;
}

.mt-5 {
  margin-top: 24px;
}

.cursor-pointer {
  cursor: pointer;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-pt {
  padding-top: @layout-header-height * 2;
  @media screen and (max-width: 576px) {
    padding-top: @layout-header-height + 32px;
  }
}

.main-pb {
  padding-bottom: 96px;
  @media screen and (max-width: 576px) {
    padding-bottom: 44px;
  }
}

.main-pv {
  .main-pt();
  .main-pb();
}

.ant-typography {
  font-weight: 400;
  line-height: 2;
}

.page-boundary {
  width: 100%;
  height: 1px;
  background: transparent;
}

.flex-grow-1 {
  flex-grow: 1;
}

.bg-primary {
  background: @primary-color;
}

.social-login {
  padding: 32px 0;

  button {
    cursor: pointer;
    margin: 0 8px;
    background-color: rgb(255, 255, 255);
    display: inline-flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.54);
    border-radius: 2px;
    border: 1px solid transparent;
    box-shadow: rgba(0, 0, 0, .24) 0 2px 2px 0, rgba(0, 0, 0, .24) 0 0 1px 0;
    width: 120px !important;
    font-family: Metropolis-Regular, serif !important;
    font-size: 14px;
    padding: 8px 12px;

    img {
      height: 21px;
      width: 21px;
    }

    span {
      padding-left: 8px;
    }
  }
}
@primary-color: #f0941e;@primary-color-hover: #ff6d00;@btn-font-weight: 500;@text-color: #212529;@link-color: #1172c4;@btn-default-border: #f0941e;@btn-default-color: #f0941e;@success-color: #007600;