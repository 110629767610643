.entry {
  min-height: 524px;

  .side-view {
    background: #ff6d00;
    padding: 44px 32px;
    height: inherit;
    margin-bottom: -2px;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 576px) {
      display: none;
    }
  }

  .wheel {

  }

  .content {
    padding: 36px 36px;
  }

  input, ::before input {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
    line-height: 24px;
    padding: 10px 20px;
    color: #666;
    height: auto;
  }

  button {
    width: 100%;
  }

  .tos {
    font-size: 12px;
    font-weight: 500;
  }

  .login {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.ant-modal-body {
  padding: 0;
}
@primary-color: #f0941e;@primary-color-hover: #ff6d00;@btn-font-weight: 500;@text-color: #212529;@link-color: #1172c4;@btn-default-border: #f0941e;@btn-default-color: #f0941e;@success-color: #007600;