.consultations {
  padding-left: 180px;
  padding-right: 180px;
  min-height: calc(100vh - 64px);

  @media screen and (max-width: 576px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  .con-list {
    margin-top: 40px;
  }

  .list-item {
    margin-bottom: 20px;
    .ant-card-body {
      padding: 12px;
    }
    .ant-avatar-image{
      width: 54px;
      height: 54px;
    }
  }
}
@primary-color: #f0941e;@primary-color-hover: #ff6d00;@btn-font-weight: 500;@text-color: #212529;@link-color: #1172c4;@btn-default-border: #f0941e;@btn-default-color: #f0941e;@success-color: #007600;