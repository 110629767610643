@import '~antd/es/style/themes/default.less';

.astro-profile {
  padding-bottom: 84px;

  .avatar {
    width: 100%;
    aspect-ratio: 1/1;
    object-fit: cover;
    border-radius: 4px;
  }

  .primary-info {
    padding: 0 64px;
  }

  @media screen and (max-width: 576px) {
    .primary-info {
      padding-left: 16px;
      padding-right: 0;
    }

    .tabs {
      margin-top: 24px;
    }
  }

  .more-options-btn {
    border: 1px solid @primary-color;
    height: 40px;
    padding: 6.4px 15px;
    font-size: 16px;
    border-radius: 2px;
    color: @primary-color;
  }

  .divider-text {
    font-weight: 300;
    font-size: 12px;
  }

  .avatar-loader {
    width: 100%;
    aspect-ratio: 1/1;
    .ant-skeleton-avatar {
      width: 100%;
      height: 100%;
    }
  }
}


@primary-color: #f0941e;@primary-color-hover: #ff6d00;@btn-font-weight: 500;@text-color: #212529;@link-color: #1172c4;@btn-default-border: #f0941e;@btn-default-color: #f0941e;@success-color: #007600;