@import '~antd/es/style/themes/default.less';
@import '../variables.less';

.astro-list-cover {
  padding: 128px 12%;

  @media screen and (max-width: 576px) {
    padding: 112px 16px 54px;
  }

  .search-main {
    border-radius: 5px;
    box-shadow: 0 7px 16px 0 rgb(62 62 62 / 10%);
    background-color: #fff;

    .divider {
      height: 32px;
      width: 1px;
      background: @divider-color;
    }

    .location-icon {
      padding-left: 12px;
    }

    .ant-select-selector, .ant-select-selector:focus {
      height: 48px;
      background: transparent;
      border: none;

      .ant-select-selection-search {
        input {
          height: 48px;
        }
      }

      .ant-select-selection-placeholder {
        color: rgb(128, 128, 128);
        display: flex;
        align-items: center;
      }

      .ant-select-selection-item {
        display: flex;
        align-items: center;
      }

    }

    .ant-select-focused .ant-select-selector,
    .ant-select-selector:focus,
    .ant-select-selector:active,
    .ant-select-open .ant-select-selector {
      border-color: transparent !important;
      box-shadow: none !important;
    }

    .ant-input-affix-wrapper {
      height: 48px;
      border: none;
      box-shadow: none;
    }

    .ant-input:focus {
      border: none !important;
      outline: 0;
      -webkit-box-shadow: none;
      box-shadow: none;
    }

    input, input:active {
      border: none;
    }
  }
}

.astro-list {
  padding-top: 44px;
  padding-bottom: 84px;
}

.sorts {
  padding-top: 44px;
}
@primary-color: #f0941e;@primary-color-hover: #ff6d00;@btn-font-weight: 500;@text-color: #212529;@link-color: #1172c4;@btn-default-border: #f0941e;@btn-default-color: #f0941e;@success-color: #007600;